<template>
  <div>
    <AdvanceTable ref="advanceTable" url="audits" :columns="columns" :extra-class="'audit'" :height="!baseFilters.isProfile ? window.height - 260 : window.height - 450" :base-filters="baseFilters" v-loading="isLoading">
      <template #cell(className)="row">
        {{ row.item.className.split('.').pop() }}
      </template>

      <template #refresh_button><span></span></template>
      <template #reset_button><span></span></template>

      <template #cell(createTime)="row">
        {{ formatWithCurrentTimezone(row.item.createTime) }}
      </template>
    </AdvanceTable>
  </div>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import AdvanceTable from "@/views/components/advanceTable/advanceTable.vue";
import { formatWithCurrentTimezone } from "./utils/time";

export default {
  components: {
    AdvanceTable,
    BCard,
    BCardText,
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
      columns: [
        // { key: "className", modelName: "className", label: "Entity", width: "200", filtertype: "input", sortable: true },
        { key: "objectId", modelName: "objectId", label: "Object ID", width: "100", filtertype: "array", sortable: true },
        { key: "eventName", modelName: "eventName", label: "Event", width: "100", filtertype: "input", sortable: true },
        { key: "propertyName", modelName: "propertyName", label: "Property Name", width: "240", filtertype: "input", sortable: true },
        { key: "oldValue", modelName: "oldValue", label: "Old Value" },
        { key: "newValue", modelName: "newValue", label: "New Value" },
        { key: "username", modelName: "username", label: "Username", width: "300", filtertype: "input", sortable: true },
        { key: "createTime", modelName: "createTime", label: "Event Time", width: "240", filtertype: "daterange", sortable: true },
      ],
      isLoading: false,
    }
  },
  props: {
    baseFilters: Object,
  },
  methods: {
    formatWithCurrentTimezone,
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    reload() {
      this.$refs.advanceTable.loadList();
    }
  },
}
</script>

<style>
.audit .b-table td {
  word-break: break-all;
}
</style>
