<template>
  <div>
    <el-dialog
      :title="$t('Audit Logs') + (viewers[0] ? ' - ' + viewers[0].name + ' #' + viewers[0].objectId : '')"
      class="borderless"
      width="95%"
      :visible.sync="isShow"
    >
      <div v-if="viewers.length > 1">
        <el-tabs v-model="activeName">
          <el-tab-pane :label="viewer.name" :name="viewer.name" v-for="viewer in viewers">
            <AuditTrailList :ref="viewer.name" :baseFilters="viewer"/>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div v-if="viewers.length === 1">
        <AuditTrailList :ref="viewers[0].name" :baseFilters="viewers[0]"/>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import AdvanceTable from "@/views/components/advanceTable/advanceTable.vue";
import AuditTrailList from "@/views/erp/AuditTrailList.vue";

export default {
  components: {
    AuditTrailList,
    AdvanceTable,
    BCard,
    BCardText,
  },
  mounted() {

  },
  data: function () {
    return {
      viewers: [],
      activeName: '',
      isShow: false,
    }
  },
  methods: {
    show(viewer) {
      this.viewers = [viewer];
      this.isShow = true;
      this.activeName = this.viewers[0].name;

      this.reload();
    },

    showMultiple(viewers) {
      this.viewers = viewers;
      this.isShow = true;
      this.activeName = this.viewers[0].name;

      this.reload();
    },

    reload() {
      this.$nextTick(() => {
        for (const [key, value] of Object.entries(this.$refs)) {
          if (value[0]) value[0].reload();
          else value.reload();
        }
      })
    }
  }
}
</script>