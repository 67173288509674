export const getAccrualIconStyle = function(type, accrual) {
  if (!accrual.accrualStatus) return {color: 'grey'};

  switch (type) {
    case 'CBO':
      if (accrual.accrualStatus.isChargeCodeCompletedCBO) return {color: 'green'}
      if (accrual.accrualStatus.isChargeCodeRequireCBO && !accrual.accrualStatus.isChargeCodeCompletedCBO) return {color: 'red'}
      if (!accrual.accrualStatus.isChargeCodeRequireCBO && !accrual.accrualStatus.isChargeCodeCompletedCBO) return {color: 'grey'}
    case 'Fulfillment':
      if (accrual.accrualStatus.isChargeCodeCompletedFulfillment) return {color: 'green'}
      if (accrual.accrualStatus.isChargeCodeRequireFulfillment && !accrual.accrualStatus.isChargeCodeCompletedFulfillment) return {color: 'red'}
      if (!accrual.accrualStatus.isChargeCodeRequireFulfillment && !accrual.accrualStatus.isChargeCodeCompletedFulfillment) return {color: 'grey'}
    case 'Others':
      if (accrual.accrualStatus.isChargeCodeCompletedOthers) return {color: 'green'}
      if (accrual.accrualStatus.isChargeCodeRequireOthers && !accrual.accrualStatus.isChargeCodeCompletedOthers) return {color: 'red'}
      if (!accrual.accrualStatus.isChargeCodeRequireOthers && !accrual.accrualStatus.isChargeCodeCompletedOthers) return {color: 'grey'}
  }
}